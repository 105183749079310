import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EncrdecrService } from 'src/app/Auth/encrdecr.service';
import { ClientsFilterService } from 'src/app/services/clients-filter.service';
import { CustomLoaderService } from 'src/app/services/custom-loader.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-clients-filter',
  templateUrl: './clients-filter.component.html',
  styleUrls: ['./clients-filter.component.scss']
})
export class ClientsFilterComponent implements OnInit {
  @Output() FilterData: EventEmitter<any> = new EventEmitter();
  @Input() isMultiSelect: boolean = false;
  @Input() DDLFilterType: string = 'T';
  @Input() HierarchyClient = true;
  @Input() FilterType: string = "byClient"; // i have changed simple FilterType to @Input type
  @Input() Param2 = '';
  clientFilterForm: FormGroup;
  LoginId: string;
  UserType: string = "";
  Param1: string = "";
  ClientData: any;
  TempClientData: any;
  FamilyData: any;
  EmployeeData: any;
  EmployeeDataH: any;
  EmployeeSetting = {};
  SelectedEmployee: any;
  SubbrokerData: any;
  HierarchyData: any;
  PlaceData: any;
  PlaceDataALL: any;
  ResponseData: any;
  selectedClient: any;
  @Input() isShowClientFilter: boolean = true;
  isdisplayFamilyName: boolean = true;
  isDisplayClientName: boolean = true;

  @Input() isShowEmpSubBrokerFilter: boolean = true;

  isDisplayEmployeeName: boolean = false; //true
  isDisplaySubBrokerName: boolean = false; //true
  isDisplayEmpSubBrokerFamilyName: boolean = false; //true
  isDisplayEmpSubClientName: boolean = false; //true

  isShowHiearchyFilter: boolean = true;

  ClientDataSetting = {};
  resultDisplayName = [];
  AllEmpData = [];
  result = [];
  selectedItems: any[] = [];
  CompanyName: string = environment.CompayName;
  SbBrokerId: string;
  RoleId: string;
  TempClientDataFinal: any;

  constructor(
    private fb: FormBuilder,
    private encryptDecrypt: EncrdecrService,
    private ClientFilterService: ClientsFilterService,
    private Loader: CustomLoaderService,
    private router: Router, private route: ActivatedRoute,
    private EncrdecrService: EncrdecrService) {
    this.clientFilterForm = this.fb.group({
      ClientBasicInfoId: [''],
      FamilyId: [''],
      EmpId: [''],
      SubbrokerId: [''],
      HierarchyId: [''],
      PlaceId: ['']
    });
  }

  ngOnInit() {
    debugger
    this.SbBrokerId = this.EncrdecrService.getDecr(sessionStorage.getItem("F44sGAGh2xwkpUL"));
    this.UserType = this.encryptDecrypt.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    if (sessionStorage.getItem('5gE4QWzFXWKewRC')) {
      this.RoleId = this.EncrdecrService.getDecr(sessionStorage.getItem('5gE4QWzFXWKewRC'));
    }
    if (this.CompanyName == 'NSDL') {
      this.isShowHiearchyFilter = false
    }
    else {
      this.isShowHiearchyFilter = true
    }


    if (this.UserType == '2') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    }
    if (this.UserType == '3') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('F44sGAGh2xwkpUL'));
      this.isShowClientFilter = false;
      this.isDisplayClientName = false;
      this.isdisplayFamilyName = false;
    }
    if (this.UserType == '1') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    }

    console.log('----------------------' + this.UserType)
    //console.log(this.router.url);

    this.BindDropdown();

    this.ClientDataSetting = {
      singleSelection: false,
      idField: 'ClientBasicInfoId',
      textField: 'clientname',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    //Added By Ravi Vaghela
    if (this.FilterType == 'byHierachy') {
      this.onCheck('byHierachy');
    }
    else if (this.FilterType == 'byEmpSubroker') {
      this.onCheck('byEmpSubroker');
    }
  }
  ngAfterViewInit() {
    $('select').select2();
    $(".ClientSelect").on("change", (e: any) => this.onChangeClient(e));
    $(".ClientSelect_SB").on("change", (e: any) => this.onChangeClient(e));
    $(".ClientSelect_EMP").on("change", (e: any) => this.onChangeClient(e));
    $(".EmployeeSelect").on("change", (e: any) => this.onChangeEmployee(e));
    $(".SubbrokerSelect").on("change", (e: any) => this.onChangeSubbroker(e));
    $(".HierarchySelect").on("change", (e: any) => this.onChangeHierarchy(e));
    $(".PlaceSelect").on("change", (e: any) => this.onChangePlace(e));
  }
  onChangeClient(event: any) {
    debugger
    let ClientBasicInfoId = event.target.value;
    if (ClientBasicInfoId != '') {


      sessionStorage.setItem("UZT6qHaDZSz66kx", this.encryptDecrypt.setEncr(ClientBasicInfoId));
      this.clientFilterForm.controls['ClientBasicInfoId'].setValue(ClientBasicInfoId);
      let filterData = this.TempClientData.filter(item => item.ClientBasicInfoId == ClientBasicInfoId);
      if (this.CompanyName == 'NSDL') {
        let IsOldCustomer = filterData[0]?.IsOldCustomer;
        if (IsOldCustomer == 'True') {
          //if (filterData[0]?.IIN == '' && filterData[0]?.IIN == null) {
          sessionStorage.setItem('3erT@Qr65TdXwq1', this.EncrdecrService.setEncr(this.ClientData[0].ClientBasicInfoId));
          $('#AlertModal_IINModification').modal({
            backdrop: 'static',
            keyboard: false
          })

          // }
        }
        else {

          if (filterData.length > 0) {
            filterData[0].ClientID = ClientBasicInfoId;
            filterData[0].flag = "Client";
            filterData[0].resultDisplayName = "";
            this.FilterData.emit(filterData[0]);
          }

        }
      }
      else {

        if (filterData.length > 0) {
          filterData[0].ClientID = ClientBasicInfoId;
          filterData[0].flag = "Client";
          filterData[0].resultDisplayName = "";
          this.FilterData.emit(filterData[0]);
        }
      }
    }
    else {

    }

  }

  onChangeEmployee(event: any) {

    let ID_Code = event.target.value;
    this.clientFilterForm.controls['EmpId'].setValue(ID_Code);
    this.LoginId = ID_Code;
    this.Param1 = 'Direct'
    this.BindDropdown();
    let MainEmpId = this.EmployeeData.filter((item) => item.ID_Code == ID_Code);


    if (this.isDisplayEmployeeName == true) {
      let json = {
        "EmployeId": ID_Code,
        "flag": "employee",
        "MainEmployeId": MainEmpId[0].EmpId
      }
      this.FilterData.emit(json);
    } else {
      this.FilterData.emit(ID_Code);
    }
    $('.SubbrokerSelect').val('').trigger('change');
  }

  onChangeSubbroker(event: any) {
    debugger
    let SubbrokerId = event.target.value;
    if (SubbrokerId != '') {

      this.clientFilterForm.controls['SubbrokerId'].setValue(SubbrokerId);

      let MainSubBrokerId = this.SubbrokerData.filter((item) => item.SubbrokerCode == SubbrokerId);


      let json = {
        "SubBrokerId": SubbrokerId,
        "flag": "SubBroker",
        "MainSubBrokerId": MainSubBrokerId[0].SubbrokerId
      }
      this.FilterData.emit(json)
      this.ClientData = [];
      this.TempClientData = [];
      this.FamilyData = [];
      this.Loader.show();
      let request = {
        "LoginId": SubbrokerId,
        "LoginType": "2",
        "FilterType": "T",
        "Param1": this.Param1,
        "Param2": ""
      }

      if (this.UserType == '1') {
        this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
      }
      this.ClientFilterService.WrapperApiCall(request, "api/ClientFilters/getClientFilters").subscribe(
        (res1: any) => {
          let res: any = JSON.parse(this.encryptDecrypt.getDecr(res1));
          this.ResponseData = res;
          //console.log(res)
          this.Loader.hide();
          if (res.code == 200) {
            console.log(res);
            if (res.ArrayOfResponse[0].ClientDetails.length > 0) {
              this.ClientData = res.ArrayOfResponse[0].ClientDetails;
              this.TempClientData = res.ArrayOfResponse[0].ClientDetails;
              let filterData = res.ArrayOfResponse[0].ClientDetails.filter(item => item.ClientGroupName !== null);
              this.FamilyData = [...new Set(filterData.map(item => item.ClientGroupName))];
            }

          }
        }, (err: any) => {
          this.Loader.hide();
          console.log(err);
        })
    }
    else {
      $(".ClientSelect").val('').trigger('change');
    }
    //this.setDropDownData();
  }
  onChangeHierarchy(event: any) {
    let HierarchyId = event.target.value;
    this.clientFilterForm.controls['HierarchyId'].setValue(HierarchyId);
    let PlaceDataFilter = this.AllEmpData.filter(item => item.HierarchyName == HierarchyId);
    this.PlaceDataALL = [...new Map(PlaceDataFilter.map(item => [item['PlaceName'], item])).values()]



  }
  onChangePlace(event: any) {
    let PlaceId = event.target.value;
    this.clientFilterForm.controls['PlaceId'].setValue(PlaceId);
    let EmployeeDataall = this.AllEmpData.filter(item => item.PlaceName == PlaceId);

    this.EmployeeDataH = [...new Map(EmployeeDataall.map(item => [item['ID_Code'], item])).values()]

    //this.setDropDownData();
  }
  onCheck(value: any) {
    debugger
    let data = window.location.pathname;
    let filterData = {
      ShowTransaction: false
    }
    this.FilterData.emit(filterData)

    //this.setDropDownData();
    this.ClearDropDown();
    this.FilterType = value;
    if (data != '/reports-new/aum-report' && data != '/clients') {
      if (this.FilterType == "byEmpSubroker") {
        if (this.CompanyName == 'NSDL') {
          if (this.RoleId == '6') {
            this.isDisplayEmployeeName = false;
          }
          else {
            this.isDisplayEmployeeName = true;
          }
        }
        else {
          this.isDisplayEmployeeName = true;
        }
        this.isDisplaySubBrokerName = true;
        this.isDisplayEmpSubClientName = true;
        this.isDisplayEmpSubBrokerFamilyName = true;
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
      } else if (this.FilterType == 'byClient') {
        this.isDisplayClientName = true;
        this.isdisplayFamilyName = true;
        this.isDisplayEmployeeName = false;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
      } else if (this.FilterType == 'byHierachy') {
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmployeeName = false;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
      }

    }
    else {
      if (data == "/reports-new/aum-report" || data == "/clients") {
        this.isShowClientFilter = false;
        if (this.FilterType == "byHierachy") {
          this.isDisplayClientName = false;
          this.isDisplayEmployeeName = false;
          this.isDisplaySubBrokerName = false;
          this.isDisplayEmpSubClientName = false;
          this.HierarchyClient = false;
        }
        else {
          this.isDisplayEmployeeName = true;
          this.isDisplaySubBrokerName = true;
          this.isDisplayEmpSubClientName = false;
          this.isDisplayClientName = false;
        }

      }
    }
    this.Loader.hide()
  }
  ClearDropDown() {
    $(".ClientSelect").prop("disabled", false);
    this.ClientData = undefined;
    // this.ClientData = this.TempClientData;

    //added by Saddam 19082024
    this.ClientData = this.TempClientDataFinal;
    $('select').val('').trigger('change.select2');
    this.clientFilterForm.patchValue({
      ClientBasicInfoId: '',
      FamilyId: '',
      EmpId: '',
      SubbrokerId: '',
      HierarchyId: '',
      PlaceId: ''
    }

    );
  }
  BindDropdown() {
    this.Loader.show();
    let request = {
      "LoginId": this.LoginId,
      "LoginType": this.UserType,
      "FilterType": this.DDLFilterType,
      "Param1": this.Param1,
      "Param2": this.Param2
    }


    if (this.UserType == '1') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    }

    this.ClientFilterService.WrapperApiCall(JSON.stringify(request), "/api/ClientFilters/getClientFilters").subscribe(
      (res1: any) => {
        let res: any = JSON.parse(this.encryptDecrypt.getDecr(res1));
        this.ResponseData = res;
        this.Loader.hide();
        if (res.code == 200) {
          console.log(res);
          this.Param2 = '';
          if (res.ArrayOfResponse[0].ClientDetails.length > 0) {
            this.ClientData = res.ArrayOfResponse[0].ClientDetails;
            this.TempClientData = res.ArrayOfResponse[0].ClientDetails;
            this.TempClientDataFinal = res.ArrayOfResponse[0].ClientDetails;
            let filterData = res.ArrayOfResponse[0].ClientDetails.filter(item => item.ClientGroupName !== null);
            this.FamilyData = [...new Set(filterData.map(item => item.ClientGroupName))];
            let rdc = sessionStorage.getItem('QQX4$E6Qndredi');
            if (this.UserType == '3') {
              setTimeout(() => {
                let cbid = this.ClientData[0].ClientBasicInfoId;
                $(".ClientSelect").val(cbid).trigger('change');
              }, 1000);

            }
            else if (rdc != undefined && rdc != null && rdc != '') {
              setTimeout(() => {
                let c = this.encryptDecrypt.getDecr(rdc)
                let c1 = JSON.parse(c);
                let cbid1 = c1.ClientBasicInfoId;
                if (cbid1 != undefined) {
                  $(".ClientSelect").val(cbid1).trigger('change');
                }
                else {
                  if (c1 != '' && c1 != null && c1 != undefined) {
                    $(".ClientSelect").val(c1).trigger('change');
                  }
                }
                $(".ClientSelect").prop("disabled", true);
              }, 1000);

            }

            sessionStorage.removeItem('QQX4$E6Qndredi')
          }
          else{
            this.ClientData = [];
            this.FamilyData = [];
            this.TempClientDataFinal = [];
            this.TempClientData = [];
          }
          if (res.ArrayOfResponse[0]?.SubbrokerDetails?.length > 0) {
            this.SubbrokerData = res.ArrayOfResponse[0].SubbrokerDetails;
          }
          else{
            this.SubbrokerData = [];
          }
          if (res.ArrayOfResponse[0]?.EmployeeDetails?.length > 0) {

            let filterData = res.ArrayOfResponse[0].EmployeeDetails.filter(item => item.ID_Code !== null);
            this.AllEmpData = filterData;
            this.EmployeeData = [...new Map(filterData.map(item => [item['ID_Code'], item])).values()]

            this.PlaceData = [...new Map(filterData.map(item => [item['PlaceName'], item])).values()]
            this.PlaceDataALL = this.PlaceData;

            this.HierarchyData = [...new Map(filterData.map(item => [item['HierarchyName'], item])).values()]
          }else{
            this.AllEmpData = [];
            // this.EmployeeData = [];
            this.PlaceDataALL = [];
            this.HierarchyData = [];
          }
        }
      }, (err: any) => {
        this.Loader.hide();
        console.log(err);
      })

  }

  setDropDownData() {
    this.ClientFilterService.setSelectedDropdownData(this.clientFilterForm.value);
  }


  onItemSelect(e: any) {
    this.ClearClientDetails();
    const ClientId = e.ClientBasicInfoId;
    const clientName = e.clientname;

    if (ClientId) {
      const i = this.result.indexOf(ClientId);
      if (i === -1) {
        if (this.result.length > 0) {
          this.resultDisplayName = [];
          this.resultDisplayName.push('Multiple');
        } else {
          this.resultDisplayName.push(clientName);
        }
        this.result.push(ClientId);
      } else {
        this.result.splice(i, 1);
        this.resultDisplayName.splice(i, 1);
        if (this.result.length > 1) {
          this.resultDisplayName = [];
          this.resultDisplayName.push('Multiple');
        } else {
          this.resultDisplayName = [];
          this.resultDisplayName.push(clientName);
        }
      }
      this.clientFilterForm.controls.ClientBasicInfoId.setValue(this.result.toString());

      let filterData = [];
      if (this.result.length > 1) {
        filterData = this.TempClientData.filter((item) => this.result.includes(item.ClientBasicInfoId));
      } else {
        filterData = this.TempClientData.filter(item => item.ClientBasicInfoId == this.result[0]);
      }

      if (this.result.length > 0) {
        filterData[0].ClientID = this.result;
        filterData[0].flag = "Client";
        filterData[0].resultDisplayName = this.resultDisplayName;
      } else {
        this.resultDisplayName = [];
        filterData = [{ "ClientID": "", "resultDisplayName": this.resultDisplayName }];
      }
      this.FilterData.emit(filterData[0]);
      console.log(filterData);

    }
  }

  onItemSelectDeselectAll(items: any, para: number) {
    this.ClearClientDetails();
    switch (para) {
      case 0:
        this.result = [];
        this.resultDisplayName = [];
        // this.selectedItems=[];
        this.resultDisplayName.push('All');
        items.map(item => {
          this.result.push(item.ClientBasicInfoId);
        });
        break;
      case 1:
        this.result = [];
        this.resultDisplayName = [];
        this.selectedItems = [];
        break;
    }

    let filterData = this.TempClientData;
    filterData[0].ClientID = this.result;
    filterData[0].flag = "Client";
    filterData[0].resultDisplayName = this.resultDisplayName;
    console.log(filterData);
    this.FilterData.emit(filterData[0]);
    this.clientFilterForm.controls.ClientBasicInfoId.setValue(this.result.toString());

  }

  ClearClientDetails() {
    this.clientFilterForm.controls.ClientBasicInfoId.setValue("");
  }


  GoToProfilePreview() {
    sessionStorage.setItem('3erT@Qr65TdXwq1', this.EncrdecrService.setEncr(this.ClientData[0].ClientBasicInfoId));
    this.router.navigate(['/Client-Profile-Preview/Preview'], { relativeTo: this.route });
  }


}
