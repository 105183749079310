import { AlphaNumericOnly, isEmailValidate, IsValidMobile, IsValidOTP, IsValidPAN, IsValidPassword, NumberOnly } from './../validation';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomLoaderService } from '../services/custom-loader.service';
import { MainHeaderMenuService } from '../services/main-header-menu.service';
import { EncrdecrService } from './../Auth/encrdecr.service';
import { LoginService } from '../services/login.service';
import { ClientDataService } from '../services/client-data.service';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined } from '../validation';
declare var $: any;

@Component({
  selector: 'app-login-nse',
  templateUrl: './login-nse.component.html',
  styleUrls: ['./login-nse.component.scss']
})
export class LoginNseComponent implements OnInit {
  LOGOPATH = 'https://unsplash.com/photos/a-man-sitting-at-a-table-using-a-laptop-computer-l_p4pay8CTM';
  UserPassword: string = "";
  txtLength = 0;
  LoginForm: FormGroup;
  LoginSubmitted: boolean = false;
  UserType: number = 3;
  IsShowLoginOption: boolean = true;
  CompanyName: string;
  WelcomeText: any = '';
  logo: string;
  NavHeight: string;
  IsMobile: boolean = false;
  logoWidth: string;
  logoHeight: string;
  IsAllowLogin: boolean = true;
  MobileLoginForm: FormGroup;
  isMobileLoginFormSubbmitted: boolean = false;
  isMobileListDisplay: boolean = false;
  EmailLoginForm: FormGroup;
  isEmailFormSubbmitted: boolean = false;
  isEmailListDisplay: boolean = false;
  ClientData: any = [];
  ClientDataBasedOnEmail: any = [];
  utm_source: string;
  databody: string;
  IshowLoginPage: boolean = false;
  IsNSDLSSO: boolean = false;
  Jiffybutton: boolean = true;
  //Added By Devang Mehta On 19-06-2024
  CaptchaText: string = "";
  InvalidCaptcha: boolean = false;
  IsPANValid: boolean = true;
  IsEmailValid: boolean = true;
  CartId: string = "";
  MainURL: string = "";
  OriginalURL: any;
  @Input("config") config: any = {
    type: 1,
    length: 6,
    cssClass: "custom",
    back: {
      stroke: "#2F9688",
      solid: "#f2efd2",
    },
    font: {
      color: "#000000",
      size: "35px",
    },
  };
  activeTab: any;
  //End By Devang Mehta
  constructor(private LoginService: LoginService,
    private EncrdecrService: EncrdecrService,
    private ShowHideHeaderMenu: MainHeaderMenuService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private Loader: CustomLoaderService,
    private ClientDataService: ClientDataService) {
    this.CompanyName = environment.CompayName;
    //Below code added by Dhruv  
    debugger

    if (environment.CompayName == "Alankit" || environment.CompayName == "Invest4Edu" || environment.CompayName == "ABML_Money") {
      this.route.queryParams.subscribe(params => {
        let ClientID = params.verify_client; //Get QueryString parameters

        if (ClientID != '' && ClientID != null && ClientID != undefined) {
          this.Loader.show();
          let NewClientId = ClientID.replaceAll(" ", "+");
          if (NewClientId != '' && NewClientId != null && NewClientId != undefined) {
            sessionStorage.setItem("b5POtv8EekKYEcn6", NewClientId)
            sessionStorage.setItem("drfgthrve54recsdv", "CBSClientProfileComplete")
            sessionStorage.setItem("uidfhcwbeh43e2d", "CLient-Profile-Complete")
            sessionStorage.setItem("cd@$hgfz#423jk", "EmailMobile");
          }
        }
      })
      let userIdArray;
      if (sessionStorage.getItem("b5POtv8EekKYEcn6")) {
        userIdArray = this.EncrdecrService.getDecr(sessionStorage.getItem("b5POtv8EekKYEcn6")).split("|");
        if (this.CompanyName == "ABML_Money" && (userIdArray[2] == "ToCartForSMS" || userIdArray[2] == "ToCart")) {



          if (sessionStorage.getItem("b5POtv8EekKYEcn6") !== undefined && sessionStorage.getItem("b5POtv8EekKYEcn6") !== null) {
            let userNameAndPassWord = "";
            if (userIdArray[1] == "ShortURL") {
              debugger
              this.CartId = userIdArray[0];
              let DataBody = {
                CartId: this.CartId
              }
              this.ClientDataService.GetCartURL(DataBody).subscribe(
                (res: any) => {
                  debugger
                  // this.Loader.hide();
                  // console.log("URLs :  " + JSON.stringify(res.ArrayOfResponse));
                  if (res && res.ArrayOfResponse && res.ArrayOfResponse.length > 0) {
                    let IsActiveLink = res.ArrayOfResponse[0].IsActiveURL
                    if (IsActiveLink == 1) {
                      //this.Loader.show();
                      this.MainURL = res.ArrayOfResponse[0].PaymentURL;
                      sessionStorage.setItem('bgi@Jbc45VY5%3zfsd', "IsFromCart");

                      this.OriginalURL = this.MainURL.split("=")[1]; //Pass argument with 1st index
                      userNameAndPassWord = this.EncrdecrService.getDecr(this.OriginalURL);
                      this.GetCartURLs(userNameAndPassWord);
                    } else {
                      this.Loader.hide();
                      this.CallAlertModal("Your link has been expired");
                    }

                  }
                  else {
                    this.Loader.hide();
                    //return;
                  }

                }, (err: any) => {
                  console.log(err);
                  this.Loader.hide();
                })
            }
            else {
              userNameAndPassWord = this.EncrdecrService.getDecr(sessionStorage.getItem('b5POtv8EekKYEcn6'));
              this.GetCartURLs(userNameAndPassWord);
            }
          }
        }

        // else if(this.CompanyName == "ABML_Money" && userIdArray[5] == "SIPCancellationLink"){

        // }

        else {

          //Modified By Devang Mehta On 05-08-2024
          if (sessionStorage.getItem("b5POtv8EekKYEcn6") !== undefined && sessionStorage.getItem("b5POtv8EekKYEcn6") !== null) {
            let userNameAndPassWord = this.EncrdecrService.getDecr(sessionStorage.getItem('b5POtv8EekKYEcn6'))
            let userIdArray = userNameAndPassWord.split("|");
            const json = {
              "Username": "",
              "Password": ""
            };
            if (userIdArray[5] == "SIPCancellationLink") {
              json.Username = userIdArray[3];
              json.Password = this.EncrdecrService.getDecr(userIdArray[4]);
              sessionStorage.setItem("cw/;chtihmSBKR}2xHDt", this.EncrdecrService.setEncr("SIPCancellationLink"));
            }
            else {
              json.Username = userIdArray[0];
              json.Password = this.EncrdecrService.getDecr(userIdArray[1]);
              // json = {
              //   "Username": userIdArray[0],
              //   "Password": this.EncrdecrService.getDecr(userIdArray[1])
              // }
            }

            this.databody = JSON.stringify(json);
            this.Loader.show();
            this.callAuthenticationController(this.databody)
          }

        }
      }


    }



    debugger
    let CompanyName = environment.CompayName;
    if (CompanyName == "NSDL") {
      this.Loader.show();
      sessionStorage.removeItem("SSOLOGIN");
      let tokenkey = '';
      this.route.queryParams.subscribe((queryParam) => {
        tokenkey = queryParam.tokenkey;
      })

      if (tokenkey != null && tokenkey != undefined && tokenkey != "") {
        sessionStorage.setItem('NSDLJiffy', 'Y');
        this.LoginService.SingleSignOnNSDL(tokenkey).subscribe(
          (res: any) => {
            debugger
            if (res != null && res != "" && res != undefined) {
              if (res.ArrayOfResponse.length > 0) {
                let pass = res.ArrayOfResponse[0].Password
                if (pass != null && pass != '' && pass != undefined) {
                  pass = this.EncrdecrService.getDecr(pass);
                }
                else {
                  pass = '';
                }
                let databody = {
                  'Username': res.ArrayOfResponse[0].Username,
                  'Password': pass
                }
                this.FinalSubmit(JSON.stringify(databody));

                sessionStorage.setItem("SSOLOGIN", 'Y');


              }
              else {
                this.Loader.hide();
                this.CallAlertModal_NSDL();
                // this.CallAlertModal('SSO Login Failed');
              }
            }
            else {
              this.Loader.hide();
              this.CallAlertModal_NSDL();
            }
          },
          (err: any) => {
            const rr = err.error;
            const er = rr.substring(1, rr.length - 1);
            const rk = er.split(', ');
            this.Loader.hide();
            this.CallAlertModal('SSO Login Failed');
          }
        )
      }
      else {
        this.Loader.hide();
        let NSDLJiffyCustomer = sessionStorage.getItem('NSDLJiffy');
        if (NSDLJiffyCustomer == 'Y' && NSDLJiffyCustomer != null && NSDLJiffyCustomer != undefined) {
          // this.IshowLoginPage = false; // for standalon login
          this.Jiffybutton = false;
          this.CallAlertModal("Mutual Fund investment made easy. Invest today and watch your wealth grow.");
        } else {
          this.IshowLoginPage = true; // for standalon login
        }
      }

    }
    else {
      this.IshowLoginPage = true; //not = nsdl
    }



    //Above code added by Dhruv  
    this.ShowHideHeaderMenu.hide();
    if (this.CompanyName == 'NSDL') {
      this.LoginForm = this.formBuilder.group({
        Username: ['', Validators.required],
        //Password1: ['', [Validators.required]],
        Password: ['', [Validators.required, Validators.pattern(IsValidPassword)]],
        Captcha: ['', [Validators.required, Validators.pattern(AlphaNumericOnly)]]
      });

      this.MobileLoginForm = this.formBuilder.group({
        Mobile: ['', [Validators.required, Validators.pattern(IsValidMobile)]],
        MobileOTP: ['', [Validators.required, Validators.pattern(IsValidOTP)]],
        Captcha: ['', [Validators.required, Validators.pattern(AlphaNumericOnly)]]
      });

      this.EmailLoginForm = this.formBuilder.group({
        Email: ['', [Validators.required, Validators.pattern(isEmailValidate)]],
        EmailOTP: ['', [Validators.required, Validators.pattern(IsValidOTP)]],
        Captcha: ['', [Validators.required, Validators.pattern(AlphaNumericOnly)]]
      });

    } else {
      this.LoginForm = this.formBuilder.group({
        Username: ['', Validators.required],
        Password: ['', [Validators.required, Validators.pattern(IsValidPassword)]],
      });
      this.MobileLoginForm = this.formBuilder.group({
        Mobile: ['', [Validators.required, Validators.pattern(IsValidMobile)]],
        MobileOTP: ['', [Validators.required, Validators.pattern(IsValidOTP)]]
      });

      this.EmailLoginForm = this.formBuilder.group({
        Email: ['', [Validators.required, Validators.pattern(isEmailValidate)]],
        EmailOTP: ['', [Validators.required, Validators.pattern(IsValidOTP)]]
      });
    }


  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  ngOnInit() {
    $(document).ready({
      function() {
        $(".numonly").on("input", function () {
          var regexp = /[^\d]/g;
          if ($(this).val().match(regexp)) {
            $(this).val($(this).val().replace(regexp, ""));
          }
        });
        $(".alphaonly").on("input", function () {
          var regexp = /[^a-zA-Z ]/g;
          if ($(this).val().match(regexp)) {
            $(this).val($(this).val().replace(regexp, ""));
          }
        });
      }
    })
    if (this.CompanyName == "NSDL") {
      this.generateCaptcha();
    }
    this.CompanyName = this.ClientDataService.GetCompanyName();
    // console.log(this.EncrdecrService.setEncr("309659"));

    this.route.queryParams.subscribe((queryParam) => {
      if (queryParam.utm_source != undefined) {
        this.utm_source = queryParam.utm_source;
        sessionStorage.setItem("3erT@Qr65TdXwq1", this.utm_source);
      }
    });
    this.SetViewPort(window.innerWidth);
    sessionStorage.setItem('nvjsd212djskd$#21ksdj', 'C')
    this.WelcomeText = this.ClientDataService.GetWelcomeText();
    this.logo = sessionStorage.getItem('logo');

    this.getCompanyDetails();
    const CorporateFag = sessionStorage.getItem('Vbhs@#%LMKIo=');
    if (CorporateFag == 'C') {

      //$('.MainOuter').css('min-height', window.innerHeight - 75);
      sessionStorage.removeItem('F44sGAGh2xwkpUL'); // Session_SubBrokerId
      sessionStorage.removeItem('Hldq31TLYwRbLJ8'); // Session_SubBrokerCode
      sessionStorage.removeItem('UZT6qHaDZSz66kx'); // Session_ClientBasicInfoId
      sessionStorage.removeItem('N1QJHdOkO7Hz8QC'); // Session_ClientBasicInfoId
      sessionStorage.removeItem('L2D3506kIHSk3E0'); // Session_UserType
      sessionStorage.removeItem('pOWgAF62qFXnZMn'); // Session_Razor
      sessionStorage.removeItem('W113TKilrF1u1gL'); // ucc code - client
      sessionStorage.removeItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
      sessionStorage.removeItem('nbj%98LS74nkjsn~@&4njrkd`!kd*294');
      sessionStorage.removeItem('hn0bHyGhfpGtrPe'); // family id
      sessionStorage.removeItem('E#$%#XCha7%*=');
      sessionStorage.removeItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$');
      sessionStorage.removeItem('Mnuoisns6wqgswj2$#');
      // sessionStorage.removeItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$');
      // sessionStorage.removeItem('logo');
      // sessionStorage.removeItem('Vbhs@#%LMKIo=');

      // if (sessionStorage.getItem('Mnuoisns6wqgswj2$#') != null &&  sessionStorage.getItem('Mnuoisns6wqgswj2$#') != "") {
      //   if (sessionStorage.getItem('Mnuoisns6wqgswj2$#').toString() == "1") {
      //     this.IsAllowLogin = false;
      //   }
      //   else {
      //     this.IsAllowLogin = true;
      //   }

      // }

    }
    else {
      return this.router.parseUrl("**");
    }
  }

  get j() {
    return this.MobileLoginForm.controls;
  }

  get e() {
    return this.EmailLoginForm.controls;
  }
  get r() {
    return this.LoginForm.controls;
  }
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ResendOtpToMobile() {
    //this.searchForm.get('from').reset();
    this.MobileLoginForm.controls['MobileOTP'].reset();
    let mobile = this.MobileLoginForm.controls["Mobile"].value;
    if (mobile == "") {
      this.CallAlertModal("Please Enter Mobile Number");
    } else {
      if (mobile.length == 10) {
        var pattern = new RegExp(IsValidMobile);
        var result = pattern.test(mobile);
        console.log(result);
        if (result == true) {
          this.Loader.show();
          let MobileRequest: any;
          if (environment.CompayName == "ABML_Money") {
            MobileRequest = {
              "VERIFICATION_ID": "ABMLOTPSMS",
              "USER_TYPE": "3",
              "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
              "Trans_Type": "Mobile",
              "Flag": "G",
              "Company": "ABML"
            };

          }
          else if (environment.CompayName == "Alankit") {
            MobileRequest = {
              "VERIFICATION_ID": "AlankitLoginSMS",
              "USER_TYPE": "3",
              "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
              "Trans_Type": "Mobile",
              "Flag": "G",
              "Company": "Alankit"
            };
          }
          else {
            MobileRequest = {
              "FullName": "",
              "VERIFICATION_ID": 'ClientOTPLoginMobile',
              "USER_TYPE": "3",
              "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
              "Trans_Type": "Mobile",
              "Flag": "G",
            };
          }

          var json = JSON.stringify(MobileRequest);
          this.sendMobileOTP(json);

        } else {
          this.CallAlertModal("Invalid Mobile Number");
          this.MobileLoginForm.reset();
        }
      } else {
        this.CallAlertModal("Invalid Mobile Number");
      }
    }
  }

  SendOtpToMobile() {
    let mobile = this.MobileLoginForm.controls["Mobile"].value;
    if (mobile.length == 10) {
      var pattern = new RegExp(IsValidMobile);
      var result = pattern.test(mobile);
      console.log(result);
      if (result == true) {
        this.Loader.show();
        let MobileRequest: any;
        if (environment.CompayName == "ABML_Money") {
          MobileRequest = {
            "VERIFICATION_ID": "ABMLOTPSMS",
            "USER_TYPE": "3",
            "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
            "Trans_Type": "Mobile",
            "Flag": "G",
            "Company": "ABML"
          };

        }
        else if (environment.CompayName == "Alankit") {
          MobileRequest = {
            "VERIFICATION_ID": "AlankitLoginSMS",
            "USER_TYPE": "3",
            "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
            "Trans_Type": "Mobile",
            "Flag": "G",
            "Company": "Alankit"
          };
        }
        else {
          MobileRequest = {
            "FullName": "",
            "VERIFICATION_ID": 'ClientOTPLoginMobile',
            "USER_TYPE": "3",
            "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
            "Trans_Type": "Mobile",
            "Flag": "G",
          };
        }
        var json = JSON.stringify(MobileRequest);
        this.sendMobileOTP(json);

      } else {
        this.CallAlertModal("Invalid Mobile Number");
        this.MobileLoginForm.reset();
      }
    } else {
      this.CallAlertModal("Invalid Mobile Number");
      this.MobileLoginForm.reset();
    }
  }

  sendMobileOTP(MobileRequest: any) {
    this.LoginService.JMOTPMobile(MobileRequest).subscribe(
      (res: any) => {
        //added by Saddam on 20092024
        if (res != null) {
          let FinalResp:string = '';
          FinalResp = this.EncrdecrService.getDecr(res.Message);
          res.Message = FinalResp.split('|')[0];
        }
        //end by Saddam on 20092024
        this.Loader.hide();
        if (res.Message == "Success") {
          this.CallAlertModal("OTP sent to your mobile no...")
        } else if (res.Message == "Overlimit") {
          this.CallAlertModal("Overlimit")
        } else {
          this.CallAlertModal("OTP operation Failed...")
        }
      }, (err: any) => {
        console.log(err);
      })
  }

  onClickReSendEmailOTP() {
    this.EmailLoginForm.controls['EmailOTP'].reset();
    let Email = this.EmailLoginForm.controls["Email"].value;
    if (Email == "") {
      this.CallAlertModal("Please enter email address.")
    } else {
      var pattern = new RegExp(isEmailValidate);
      var result = pattern.test(Email);
      if (result == true) {
        this.Loader.show();
        let EmailRequest: any;
        if (environment.CompayName == "ABML_Money") {
          EmailRequest = {
            "VERIFICATION_ID": "ABMLOTP",
            "USER_TYPE": "3",
            "EmailId": this.EmailLoginForm.controls["Email"].value,
            "Trans_Type": "EmailID",
            "Flag": "G",
            "Company": "ABML"
          };

        }
        else if (environment.CompayName == "Alankit") {
          EmailRequest = {
            "VERIFICATION_ID": "AlankitLoginEmail",
            "USER_TYPE": "3",
            "EmailId": this.EmailLoginForm.controls["Email"].value,
            "Trans_Type": "EmailID",
            "Flag": "G",
            "Company": "Alankit"
          };
        }
        else {
          EmailRequest = {
            "FullName": "",
            "VERIFICATION_ID": 'ClientOTPLoginEmail',
            "USER_TYPE": "3",
            "EmailId": this.EmailLoginForm.controls["Email"].value,
            "Trans_Type": 'EmailID',
            "Flag": "G"
          }
        }
        var json = JSON.stringify(EmailRequest);
        this.SendEmailOTP(json);
      } else {
        this.CallAlertModal("Invalid Email Address")
      }
    }
  }

  SendOtpToEmail() {
    ;
    let Email = this.EmailLoginForm.controls["Email"].value;
    if (Email == "") {
      this.CallAlertModal("Please enter email address.")
    } else {
      var pattern = new RegExp(isEmailValidate);
      var result = pattern.test(Email);
      if (result == true) {
        this.Loader.show();
        let EmailRequest: any;
        if (environment.CompayName == "ABML_Money") {
          EmailRequest = {
            "VERIFICATION_ID": "ABMLOTP",
            "USER_TYPE": "3",
            "EmailId": this.EmailLoginForm.controls["Email"].value,
            "Trans_Type": "EmailID",
            "Flag": "G",
            "Company": "ABML"
          };

        }
        else if (environment.CompayName == "Alankit") {
          EmailRequest = {
            "VERIFICATION_ID": "AlankitLoginEmail",
            "USER_TYPE": "3",
            "EmailId": this.EmailLoginForm.controls["Email"].value,
            "Trans_Type": "EmailID",
            "Flag": "G",
            "Company": "Alankit"
          };
        }
        else {
          EmailRequest = {
            "FullName": "",
            "VERIFICATION_ID": 'ClientOTPLoginEmail',
            "USER_TYPE": "3",
            "EmailId": this.EmailLoginForm.controls["Email"].value,
            "Trans_Type": 'EmailID',
            "Flag": "G"
          }
        }
        var json = JSON.stringify(EmailRequest);
        this.SendEmailOTP(json);
      } else {
        this.CallAlertModal("Please enter valid email address..")
      }
    }
  }

  SendEmailOTP(EmailRequest: any) {
    this.LoginService.JMOTPEmail(EmailRequest).subscribe(
      (res: any) => {
        this.Loader.hide();
        //added by Saddam on 20092024
        if (res != null) {
          let FinalResp:string = '';
          FinalResp = this.EncrdecrService.getDecr(res.Message);
          res.Message = FinalResp.split('|')[0];
        }
        //end by Saddam on 20092024
        if (res.Message == "Success") {
          this.CallAlertModal("OTP sent to your EmailID...")
        } else if (res.Message == "Overlimit") {
          this.CallAlertModal("Ov-e-rlimit")
        } else {
          this.CallAlertModal("OTP operation Failed...")
        }
      }, (err: any) => {
        console.log(err);
      })
  }

  OnMobileLogin() {
    debugger
    this.isMobileLoginFormSubbmitted = true;
    if (this.MobileLoginForm.invalid) {
      return
    } else {

      //Added By Devang Mehta On 19-06-2024
      if (this.CompanyName == "NSDL") {
        if (this.CaptchaText != this.MobileLoginForm.controls.Captcha.value) {
          this.InvalidCaptcha = true;
          return;
        }
        else {
          this.InvalidCaptcha = false;
        }
      }
      //End By Devang Mehta

      this.Loader.show();
      const MobileVerifyRequest = {
          "FullName": "",
          "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
          "Trans_Type": "Mobile",
          "Flag": "V",
          "OTP": this.MobileLoginForm.controls["MobileOTP"].value
        };
      
      var json = JSON.stringify(MobileVerifyRequest);
      this.LoginService.JMOTPMobile(json).subscribe(
        (res: any) => {
          //this.Loader.hide();

          //added by Saddam on 20092024
          if (res != null) {
            let FinalResp:string = '';
            FinalResp = this.EncrdecrService.getDecr(res.Message);
            res.Message = FinalResp.split('|')[0];
          }
          //end by Saddam on 20092024

          if (res.Message == "Verified") {
            // this.CallAlertModal("OTP Verified Successfully");
           // this.Loader.show();
            const json = {
              "MobileNo": this.EncrdecrService.setEncr(this.MobileLoginForm.controls["Mobile"].value),
              "Email": "",
              "flag": "BasedOnMobile",
              "UserType": 3 //added by Saddam on 20092024
            }
            let data = JSON.stringify(json)
            this.LoginService.fetchClientDetailsBasedOnMobileOrEmail(data).subscribe(
              (res: any) => {
                //this.Loader.hide();
                if (res.ArrayOfResponse != null && res.ArrayOfResponse != undefined) {
                  //if (res.ArrayOfResponse.length === 1 || res.ArrayOfResponse.length >= 1) {
                  if (res.ArrayOfResponse.length === 1) {
                    let password = this.EncrdecrService.getDecr(res.ArrayOfResponse[0].Password);
                    let PANNo = this.EncrdecrService.getDecr(res.ArrayOfResponse[0].PANNo);//added by Saddam on 20092024
                    const json = {
                      "Username": PANNo,
                      "Password": password
                    }
                    let databody = JSON.stringify(json);
                    this.callAuthenticationController(databody)
                  } 
                  else {
                    this.Loader.hide();
                    if (res.ArrayOfResponse.length === 0) {
                      this.CallAlertModal('If you are a new client of NSDL Payments Bank, please click on "click here to create a new account."');
                      // this.CallAlertModal("unauthorized user Sorry...please contact Branch.");
                      this.MobileLoginForm.reset();
                    } 
                    else {
                      //added by Saddam on 20092024
                      res.ArrayOfResponse.forEach(response => {
                        response.PANNo = this.EncrdecrService.getDecr(response.PANNo);
                        response.MobileNo = this.EncrdecrService.getDecr(response.MobileNo);
                        response.EmailId = this.EncrdecrService.getDecr(response.EmailId);
                      });
                      //end by saddam
                      this.ClientData = res.ArrayOfResponse
                      this.isMobileListDisplay = true;
                      $('#AlertModal').modal('hide');
                      $('#AlertModalLogin').modal({
                        backdrop: 'static',
                        keyboard: false
                      });
                    }
                  }
                }
              }, (err: any) => {
                this.Loader.hide();
                console.log(err);
              })
          } 
          else if (res.Message == "OTP Code is Expired") {
            this.Loader.hide();
            this.CallAlertModal("OTP Code is Expired")
            this.isMobileListDisplay = false;
          } 
          else if (res.Message == "User Not Exist") {
            this.Loader.hide();
            this.CallAlertModal(res.Message);
            this.MobileLoginForm.reset();
            this.isMobileListDisplay = false;
          } 
          else if (res.Message == "Overlimit") {
            this.Loader.hide();
            this.CallAlertModal("Overlimit!! please try after 5 minutes.");
            this.MobileLoginForm.reset();
            this.isMobileListDisplay = false;
          } 
          else if (res.Message == "Invalid OTP Code") {
            this.Loader.hide();
            this.CallAlertModal("Invalid OTP Code");
            this.MobileLoginForm.reset();
            this.isMobileListDisplay = false;
          } 
          else {
            this.Loader.hide();
            this.CallAlertModal("Verified operation Faild...")
            this.MobileLoginForm.reset();
            this.isMobileListDisplay = false;
          }
        }, (err: any) => {
          this.Loader.hide();
          console.log(err);
        })
    }
  }

  onEmailLoginClick() {
    debugger
    this.isEmailFormSubbmitted = true;
    if (this.EmailLoginForm.invalid) {
      return
    } else {

      //Added By Devang Mehta On 19-06-2024
      if (this.CompanyName == "NSDL") {
        if (this.CaptchaText != this.EmailLoginForm.controls.Captcha.value) {
          this.InvalidCaptcha = true;
          return;
        }
        else {
          this.InvalidCaptcha = false;
        }
      }
      //End by Devang Mehta


      this.Loader.show();
      let Email = this.EmailLoginForm.controls["Email"].value;
      const EmailVerifyRequest = {
        "FullName": "",
        "EmailId": this.EmailLoginForm.controls["Email"].value,
        "Trans_Type": "EmailID",
        "Flag": "V",
        "OTP": this.EmailLoginForm.controls["EmailOTP"].value
      };
      var json = JSON.stringify(EmailVerifyRequest);
      this.LoginService.JMOTPEmail(json).subscribe(
        (res: any) => {
          // this.Loader.hide();
          //added by Saddam on 20092024
          if (res != null) {
            let FinalResp:string = '';
            FinalResp = this.EncrdecrService.getDecr(res.Message);
            res.Message = FinalResp.split('|')[0];
          }
          //end by Saddam on 20092024

          if (res.Message == "Verified") {
            // this.CallAlertModal("OTP Verified Successfully");
            const json = {
              "MobileNo": "",
              "Email": this.EncrdecrService.setEncr(this.EmailLoginForm.controls["Email"].value),
              "flag": "BasedOnEmail",
              "UserType": 3 //added by Saddam on 20092024
            }
            sessionStorage.setItem('aojkcfjoenfoawendv334', this.EncrdecrService.setEncr(this.EmailLoginForm.controls["Email"].value));
            let data = JSON.stringify(json)
            this.Loader.show();
            this.LoginService.fetchClientDetailsBasedOnMobileOrEmail(data).subscribe(
              (res: any) => {
                
                if (res.ArrayOfResponse != null && res.ArrayOfResponse != undefined) {
                  //if (res.ArrayOfResponse.length === 1 || res.ArrayOfResponse.length >= 1) {
                  if (res.ArrayOfResponse.length === 1) {
                    let PANNo = this.EncrdecrService.getDecr(res.ArrayOfResponse[0].PANNo);//added by Saddam on 20092024
                    let password = this.EncrdecrService.getDecr(res.ArrayOfResponse[0].Password)
                    const json = {
                      "Username": PANNo,
                      "Password": password
                    }
                    let databody = JSON.stringify(json);
                    this.callAuthenticationController(databody)
                  } else {
                    this.Loader.hide();
                    if (res.ArrayOfResponse.length === 0) {
                      this.CallAlertModal('If you are a new client of NSDL Payments Bank, please click on "click here to create a new account."');
                      // this.CallAlertModal("unauthorized user Sorry contact Branch.");
                      this.EmailLoginForm.reset();
                    } else {
                      //added by Saddam on 20092024
                      res.ArrayOfResponse.forEach(response => {
                        response.PANNo = this.EncrdecrService.getDecr(response.PANNo);
                        response.MobileNo = this.EncrdecrService.getDecr(response.MobileNo);
                        response.EmailId = this.EncrdecrService.getDecr(response.EmailId);
                      });
                      //end by saddam
                      this.ClientDataBasedOnEmail = res.ArrayOfResponse
                      this.isEmailListDisplay = true;
                      $('#AlertModal').modal('hide');
                      $('#AlertModalLoginEmail').modal({
                        backdrop: 'static',
                        keyboard: false
                      });
                    }
                  }
                }
              }, (err: any) => {
                console.log(err);
                this.Loader.hide();
              })
          } else if (res.Message == "OTP Code is Expired") {
            this.Loader.hide();
            this.CallAlertModal("OTP Code is Expired");
            this.isEmailListDisplay = false;
          } else if (res.Message == "User Not Exist") {
            this.Loader.hide();
            this.CallAlertModal(res.Message);
            this.EmailLoginForm.reset();
            this.isEmailListDisplay = false;
          } else if (res.Message == "Overlimit") {
            this.Loader.hide();
            this.CallAlertModal("Overlimit!! please try after 5 minutes.");
            this.EmailLoginForm.reset();
            this.isEmailListDisplay = false;
          } else if (res.Message == "Invalid OTP Code") {
            this.Loader.hide();
            this.CallAlertModal("Invalid OTP Code");
            this.EmailLoginForm.reset();
            this.isEmailListDisplay = false;
          } else {
            this.Loader.hide();
            this.CallAlertModal("Verified operation Faild...")
            this.EmailLoginForm.reset();
            this.isEmailListDisplay = false;
          }
        }, (err: any) => {
          this.Loader.hide();
          console.log(err);
        })
    }
  }

  OnClickClientSelection(clientDetails: any) {
    if (clientDetails != undefined && clientDetails != null) {
      this.Loader.show();
      let password = this.EncrdecrService.getDecr(clientDetails.Password)
      const json = {
        "Username": clientDetails.PANNo,
        "Password": password
      }
      let databody = JSON.stringify(json);
      this.callAuthenticationController(databody)
    } else {
      this.Loader.hide();
      this.CallAlertModal("Something goes Wrong");
    }
  }

  BasedonEmailSelection(clientDetails: any) {
    if (clientDetails != undefined && clientDetails != null) {
      this.Loader.show();
      let password = this.EncrdecrService.getDecr(clientDetails.Password)
      const json = {
        "Username": clientDetails.PANNo,
        "Password": password
      }
      let databody = JSON.stringify(json);
      this.callAuthenticationController(databody)
    } else {
      this.CallAlertModal("Something goes Wrong");
    }
  }

  SetViewPort(w) {
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
  // ngAfterViewInit() {
  //   $('.passwordtxt').on('input', (e: any) => this.ontypePassword(e));
  // };
  // ontypePassword(e) {
  //   //console.log(e.target.value);
  //   var getPassVal = e.target.value;
  //   if (this.txtLength > getPassVal.length) {
  //     if (getPassVal.length == 0) {
  //       this.UserPassword = "";
  //       this.txtLength = 0;
  //       this.LoginForm.controls['Password'].setValue(this.UserPassword);
  //     }
  //     else {
  //       this.txtLength = getPassVal.length;
  //       this.UserPassword = this.UserPassword.slice(0, -1);
  //       this.LoginForm.controls['Password'].setValue(this.UserPassword);
  //     }
  //   }
  //   else {
  //     this.txtLength = getPassVal.length;
  //     var OneWord = getPassVal[getPassVal.length - 1]
  //     this.UserPassword = this.UserPassword + OneWord;
  //     this.LoginForm.controls['Password'].setValue(this.UserPassword);
  //     if (this.LoginForm.controls.Password1.value != '$') {
  //       var strReplaceString = this.LoginForm.controls.Password1.value;
  //       strReplaceString = strReplaceString.replace(/[^$]/g, "");
  //       // $('#<%=txtPassword.ClientID%>').val(strReplaceString + '$');
  //       this.LoginForm.controls['Password1'].setValue(strReplaceString + '$');
  //     }
  //     else {
  //       //$('#<%=txtPassword.ClientID%>').val($('#<%=txtPassword.ClientID%>').val() + '$');
  //       this.LoginForm.controls['Password1'].setValue(this.LoginForm.controls.Password1.value + '$')
  //     }
  //   }
  // }
  ngAfterViewInit() {
    $(document).ready({
      function() {
        $(".numonly").on("input", function () {
          var regexp = /[^\d]/g;
          if ($(this).val().match(regexp)) {
            $(this).val($(this).val().replace(regexp, ""));
          }
        });
        $(".alphaonly").on("input", function () {
          var regexp = /[^a-zA-Z ]/g;
          if ($(this).val().match(regexp)) {
            $(this).val($(this).val().replace(regexp, ""));
          }
        });
      }
    })
  }
  onSubmit() {
    debugger
    this.LoginSubmitted = true;
    if (this.LoginForm.invalid) {
      return;
    }
    else {
      if (this.CompanyName == 'NSDL') {
        if (this.CaptchaText != this.LoginForm.controls.Captcha.value) {

          this.InvalidCaptcha = true;
          return;
        }
        else {
          this.InvalidCaptcha = false;
          this.LoginForm.controls["Captcha"].setValue("");
          this.LoginForm.updateValueAndValidity();
        }
      }

      this.Loader.show();
      const DataBody = JSON.stringify(this.LoginForm.value);
      this.FinalSubmit(DataBody);
    }
  }

  FinalSubmit(DataBody) {
    debugger
    sessionStorage.setItem('Tgsh@#3734fjfskshh==', '173.212.234.56');
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    this.LoginService.PostLogin_NSE_Info(DataBody, this.UserType, IpAddress).subscribe(
      (res: any) => {
        if (res != "Invalid Username and Password" && res.TOKEN_ID != null) {
          sessionStorage.setItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF', this.EncrdecrService.setEncr("1"));
          sessionStorage.setItem('YKHkxDnoSoOe6PdTjKrqfm4z6', this.EncrdecrService.setEncr("19"));
          sessionStorage.setItem('`huR,8@RK9^??b4 -.hfs!!uR,XX', res.TOKEN_ID);
          sessionStorage.setItem('F44sGAGh2xwkpUL', res.LOGIN_ID); // Session_SubBrokerId
          sessionStorage.setItem('V4WGROuLLBE6t98', res.USER_NAME); // Session_UserName
          sessionStorage.setItem('V8hpPfITcxhEI9x', res.EMAIL_ID); // Session_UserEmail
          sessionStorage.setItem('3erT@Qr65TdXwq1', res.LOGIN_ID);//Added by Bhushan
          sessionStorage.setItem('Xe003SRRyYEoOC2', res.MOBILE); // Session_UserMobile
          // let login_id = this.EncrdecrService.getDecr(res.LOGIN_ID)
          // let SubBrokerCode = this.EncrdecrService.getDecr(res.SubBrokerCode);
          // let EmpCode = this.EncrdecrService.getDecr(res.EmployeeCode);
          // let USER_ID = this.EncrdecrService.getDecr(res.USER_ID)
          //this.ShowHideHeaderMenu.show();
          sessionStorage.setItem('pOWgAF62qFXnZMn', ''); // RazorCustId
          ;
          sessionStorage.setItem('Hldq31TLYwRbLJ8', res.SubBrokerCode); // Session_SubBrokerCode
          sessionStorage.setItem('UHBhbkbm5nbhkbj', res.EmployeeCode); // Session_EmployeeCode
          sessionStorage.setItem('L2D3506kIHSk3E0', res.USER_TYPE); // Session_UserType
          sessionStorage.setItem('qAkZrQYLWNBSlki', res.LOGIN_ID); // Session_CBId
          sessionStorage.setItem('pi4ZU2P3M8JE4q7', ''); // Session_B64CompanyLogo

          if (res.Family_ID !== null && res.Family_ID !== undefined && res.Family_ID !== 'null') {
            sessionStorage.setItem('hn0bHyGhfpGtrPe', res.Family_ID);  // Session_Family_ID
          }
          let ut = res.USER_TYPE;
          if (ut != undefined && ut != null && ut != '') {
            if (this.EncrdecrService.getDecr(ut) == '3') {
              sessionStorage.setItem('m5JkoXISmYRAIuY', res.USER_ID); // Session_UserId
              sessionStorage.setItem('UZT6qHaDZSz66kx', res.LOGIN_ID); // Session_ClientBasicInfoId
              sessionStorage.setItem('N1QJHdOkO7Hz8QC', res.LOGIN_ID);
              sessionStorage.setItem('ySxterqMDG7RY2qTpuzrfA', this.EncrdecrService.setEncr("1"));
              sessionStorage.setItem('AqVk7klfa+b0nlVjO8Y90I=', res.RiskProfile); //Aggressive/Moderate etc
              sessionStorage.setItem('aaIHl0pIiYe71gyyxC/5xA==', res.RiskProfileStatus);//Active or Expired
              sessionStorage.setItem('LWHTWnSDkv4+qaQXsO6tHYU=', res.RiskProfileLevel); //risk Level of client
              sessionStorage.setItem('6l0NqN5kkIZCaDvdzUfYVK8g=', res.RiskProfileInsertedDate)
              this.Loader.hide();
              if (this.utm_source == res.LOGIN_ID) {
                sessionStorage.setItem("3erT@Qr65TdXwq1", res.LOGIN_ID);
                this.router.navigate(["/Client-Profile-Preview/Preview"]);
              }
              else {
                window.location.href = '/client-dashboard';
              }
            }
            else {
              this.Loader.hide();
              this.CallAlertModal('Please enter valid username and password');
            }
          }
          else {
            this.Loader.hide();
            this.CallAlertModal('Please enter valid username and password');
          }
        }
        else if (res == "Already_loggedin") {
          this.Loader.hide();
          this.CallAlertModal('You are already logged in. Please logout from another device and try again.');
        }
        else {
          this.Loader.hide();
          this.CallAlertModal('Please enter valid username and password');
        }
      },
      (err: any) => {
        const rr = err.error;
        const er = rr.substring(1, rr.length - 1);
        const rk = er.split(', ');
        this.Loader.hide();
        this.CallAlertModal('Please enter valid username and password');
      }
    );
  }

  SendUserType() {

    sessionStorage.setItem('L2D3506kIHSk3E0', this.EncrdecrService.setEncr(this.UserType.toString()));
    sessionStorage.setItem('sdfkjo2352lkj', "ForgotPass");
  }
  CallAlertModal(msg: any) {
    $('#AlertModal').modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#AlertModalContent').text(msg);
  }

  CallAlertModal_NSDL() {
    // alert('test');
    $('#AlertModal_NSDL').modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  CallAlertModal_NSDLJiffy() {
    // alert('test');
    $('#AlertModal_NSDLJiffy').modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  RedirectToOnboarding() {
    if (this.Jiffybutton) {
      this.router.navigate(['/client-preOnboading'], { relativeTo: this.route });
    }
    else {
      $('#AlertModal_NSDL').modal('hide');
    }
  }
  getCompanyDetails() {
    this.Loader.show();
    this.ClientDataService.getCompanyDetails(environment.CompanyId).subscribe((data: any) => {
      this.Loader.hide();
      if (data != null && data.ArrayOfResponse.length != 0) {
        let rk = data.ArrayOfResponse[0];
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("0"));
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "0");
        sessionStorage.setItem('E#$%#XCha7%*=', this.EncrdecrService.getDecr(rk.Dummy2));
        sessionStorage.setItem('Vbhs@#%LMKIo=', this.EncrdecrService.getDecr(rk.Dummy1));
        sessionStorage.setItem('DufjGDH3843JE', this.EncrdecrService.getDecr(rk.Dummy4));
        sessionStorage.setItem('PfdkSJDNWks2323Ms', this.EncrdecrService.getDecr(rk.Dummy3));
        sessionStorage.setItem('Arh34SDBJSSiF', this.EncrdecrService.getDecr(rk.Dummy5));
        sessionStorage.setItem('Fhd63asja$%Wsdjs9304#@dfvdjfjd', this.EncrdecrService.getDecr(rk.Dummy6));
        sessionStorage.setItem('%^&s8g9b!@fla%g', this.EncrdecrService.getDecr(rk.Dummy7));
        if (!isNullOrUndefined(this.EncrdecrService.getDecr(rk.Dummy10))) {
          sessionStorage.setItem('dsa&hdhbc%ube=', this.EncrdecrService.getDecr(rk.Dummy10));
        } else {
          sessionStorage.setItem('dsa&hdhbc%ube=', 'false');
        }
        sessionStorage.setItem('cIe+MhBUozWO3+mKZd2to=', this.EncrdecrService.getDecr(rk.EnableRiskProfile));
      }
    });
  }
  Gotolink() {
    // window.location.href = 'https://alphaekyc.adityabirlamoney.com/?utm_source=abcdapp&utm_medium=abcdapp';
    window.location.href = 'https://alphaekyc.adityabirlamoney.com/';
  }
  callAuthenticationController(DataBody: any) {
    this.Loader.show();
    //if(this.databody!=='' && this.databody !== null){
    sessionStorage.setItem('Tgsh@#3734fjfskshh==', '173.212.234.56');
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    this.LoginService.PostLogin_NSE_Info(DataBody, this.UserType, IpAddress).subscribe(
      (res: any) => {
        this.databody = "";
        if (res != "Invalid Username and Password" && res.TOKEN_ID != null) {
          sessionStorage.setItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF', this.EncrdecrService.setEncr("1"));
          sessionStorage.setItem('YKHkxDnoSoOe6PdTjKrqfm4z6', this.EncrdecrService.setEncr("19"));
          sessionStorage.setItem('`huR,8@RK9^??b4 -.hfs!!uR,XX', res.TOKEN_ID);
          sessionStorage.setItem('F44sGAGh2xwkpUL', res.LOGIN_ID); // Session_SubBrokerId
          sessionStorage.setItem('V4WGROuLLBE6t98', res.USER_NAME); // Session_UserName
          sessionStorage.setItem('V8hpPfITcxhEI9x', res.EMAIL_ID); // Session_UserEmail

          sessionStorage.setItem('Xe003SRRyYEoOC2', res.MOBILE); // Session_UserMobile
          sessionStorage.setItem('pOWgAF62qFXnZMn', ''); // RazorCustId
          ;
          sessionStorage.setItem('Hldq31TLYwRbLJ8', res.SubBrokerCode); // Session_SubBrokerCode
          sessionStorage.setItem('UHBhbkbm5nbhkbj', res.EmployeeCode); // Session_EmployeeCode
          sessionStorage.setItem('L2D3506kIHSk3E0', res.USER_TYPE); // Session_UserType
          sessionStorage.setItem('qAkZrQYLWNBSlki', res.LOGIN_ID); // Session_CBId
          sessionStorage.setItem('pi4ZU2P3M8JE4q7', ''); // Session_B64CompanyLogo
          if (res.Family_ID !== null && res.Family_ID !== undefined && res.Family_ID !== 'null') {
            sessionStorage.setItem('hn0bHyGhfpGtrPe', res.Family_ID);  // Session_Family_ID
          }
          let ut = res.USER_TYPE;
          if (ut != undefined && ut != null && ut != '') {
            if (this.EncrdecrService.getDecr(ut) == '3') {

              sessionStorage.setItem('m5JkoXISmYRAIuY', res.USER_ID);
              sessionStorage.setItem('UZT6qHaDZSz66kx', res.LOGIN_ID); // Session_ClientBasicInfoId
              sessionStorage.setItem('N1QJHdOkO7Hz8QC', res.LOGIN_ID);
              sessionStorage.setItem('ySxterqMDG7RY2qTpuzrfA', this.EncrdecrService.setEncr("1"));

              sessionStorage.setItem('AqVk7klfa+b0nlVjO8Y90I=', res.RiskProfile); //Aggressive/Moderate etc
              sessionStorage.setItem('aaIHl0pIiYe71gyyxC/5xA==', res.RiskProfileStatus);//Active or Expired
              sessionStorage.setItem('LWHTWnSDkv4+qaQXsO6tHYU=', res.RiskProfileLevel); //risk Level of client
              sessionStorage.setItem('6l0NqN5kkIZCaDvdzUfYVK8g=', res.RiskProfileInsertedDate)
              // this.Loader.hide();
             
              //Added by Dhruv
              if (sessionStorage.getItem("b5POtv8EekKYEcn6") !== undefined && sessionStorage.getItem("b5POtv8EekKYEcn6") !== null) {
                sessionStorage.setItem("3erT@Qr65TdXwq1", res.LOGIN_ID);
                sessionStorage.setItem("zswDI2W9zp5Cj5j", this.EncrdecrService.setEncr("1"));

                if (sessionStorage.getItem("rfrg343") !== undefined && sessionStorage.getItem("rfrg343") !== null && sessionStorage.getItem("rfrg343") == "Cart") {
                  //Added By Devang Mehta On 08/02/2024
                  sessionStorage.setItem('bgi@Jbc45VY5%3zfsd', "IsFromCart");
                  //

                  window.location.href = '/transaction-new/cart';
                }
                //Added By Devang Mehta On 05-08-2024
                else if (sessionStorage.getItem("cw/;chtihmSBKR}2xHDt") !== undefined && sessionStorage.getItem("cw/;chtihmSBKR}2xHDt") !== null && this.EncrdecrService.getDecr(sessionStorage.getItem("cw/;chtihmSBKR}2xHDt")) == "SIPCancellationLink") {
                  window.location.href = '/reports/cancel-sip-report';
                }
                else {
                  window.location.href = '/Client-Profile-Preview/Preview';
                }

              } else {
                window.location.href = '/client-dashboard';
              }

              // }
            }
            else {
              this.Loader.hide();
              this.CallAlertModal('Please enter valid username and password');
            }
          }
          else {
            this.Loader.hide();
            this.CallAlertModal('Please enter valid username and password');
          }
        }
        else if (res == "Already_loggedin") {
          this.Loader.hide();
          this.CallAlertModal('You are already logged in. Please logout from another device and try again.');
        }
        else {
          this.Loader.hide();
          this.CallAlertModal('Please enter valid username and password');
        }
        this.Loader.hide();
      },
      (err: any) => {
        const rr = err.error;
        const er = rr.substring(1, rr.length - 1);
        const rk = er.split(', ');
        this.Loader.hide();
        this.CallAlertModal('Please enter valid username and password');
      }
    );
    //}
  }

  //Added By Devang Mehta On 19-06-2024
  generateCaptcha() {
    // this.CaptchaText = Math.floor(1000 + Math.random() * 9000).toString();
    let TempCaptcha = "";
    const captaVariable = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 6; i++) {
      TempCaptcha += captaVariable.charAt(Math.random() * captaVariable.length)
    }
    //Addded
    // this.CaptchaText = Math.floor(  + Math.random() * 9000).toString();
    this.CaptchaText = TempCaptcha;
    this.LoginForm.controls["Captcha"].setValue("");
    setTimeout(() => {
      let captcahCanvas: any
      switch (this.activeTab) {
        case 'One':
          captcahCanvas = document.getElementById("captcahCanvasMobile");
          break;
        case 'Two':
          captcahCanvas = document.getElementById("captcahCanvasEmail");
          break;
        case 'Three':
          captcahCanvas = document.getElementById("captcahCanvasPAN");
          break;
        default:
          captcahCanvas = document.getElementById("captcahCanvasMobile");
          break;
      }
      var ctx = captcahCanvas.getContext("2d");
      ctx.fillStyle = this.config.back.solid;
      ctx.fillRect(0, 0, captcahCanvas.width, captcahCanvas.height);

      ctx.beginPath();

      captcahCanvas.style.letterSpacing = 5 + "px";
      ctx.font = this.config.font.size + " " + this.config.font.family;
      ctx.fillStyle = this.config.font.color;
      ctx.textBaseline = "middle";
      ctx.fillText(this.CaptchaText, 40, 50);
      if (this.config.back.stroke) {
        ctx.strokeStyle = this.config.back.stroke;
        for (var i = 0; i < 150; i++) {
          ctx.moveTo(Math.random() * 300, Math.random() * 300);
          ctx.lineTo(Math.random() * 300, Math.random() * 300);
        }
        ctx.stroke();
      }

      // this.captchaCode.emit(char);
    }, 100);
  }
  validateNumbers(event: Event): void {
    debugger
    const input = event.target as HTMLInputElement;
    const regexp = /[^\d]/g;
    if (input.value.match(regexp)) {
      input.value = input.value.replace(regexp, '');
    }
  }
  ValidatePattern(flag, e) {

    if (flag == "PAN") {
      var reg = new RegExp(IsValidPAN);
      this.IsPANValid = reg.test(e.target.value);
    }
    if (flag == "Email") {
      var regEmail = new RegExp("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}");
      this.IsEmailValid = regEmail.test(e.target.value);
    }
    else if (flag == "Number") {
      const charCode = (e.which) ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
    else if (flag == "NumberandCharc") {
      const k = (e.which) ? e.which : e.keyCode;
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));

    }
    else if (flag == "Chars") {

      const k = (e.which) ? e.which : e.keyCode;
      if ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32) {

      }
      else {
        e.preventDefault();
      }

    }
  }

  GetCartURLs(userNameAndPassWord: any) {
    debugger
    let isLinkExpired: boolean = false;
    let userIdArray = userNameAndPassWord.split("|");
    const json = {
      "Username": userIdArray[0],
      "Password": this.EncrdecrService.getDecr(userIdArray[1])
    }
    if (userIdArray[2] == "ForAnotherUcc") {
      sessionStorage.setItem("AAyfrb8xjGNaP9d", this.EncrdecrService.setEncr("IsForClientAnotherUcc"))
    } else if (userIdArray[2] == "ToCart") {
      sessionStorage.setItem("rfrg343", "Cart")
      try {
        debugger
        let LinkDate: any = new Date(userIdArray[3]);
        let CurrentDate: any = new Date();
        let hours = Math.abs(LinkDate - CurrentDate) / 36e5;
        if (hours > 48) {
          isLinkExpired = true;
        }
      } catch (e: any) {
        console.log(e)
      }
    }
    //////link based transaction
    else if (userIdArray[2] == "ToCartForSMS") {
      sessionStorage.setItem("rfrg343", "Cart");
      this.CartId = userIdArray[3];
    }
    if (!isLinkExpired) {
      this.databody = JSON.stringify(json);
      this.Loader.show();
      this.callAuthenticationController(this.databody)
    } else {
      this.CallAlertModal("Your link has been expired")
    }

  }

  switchTab(ActiveTab: any) {
    this.activeTab = ActiveTab;
    if (this.CompanyName == 'NSDL') {
      this.generateCaptcha();
    }
  }

  //End By Devang Mehta

}
